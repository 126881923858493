import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { authRole } from '../index';
import { THEME, USERID } from './App'
import '../css/nav.css';

export default function NavBar(props) {
  const [dropdown, setDropdown] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)
  const [role, setRole] = useState(props.constants.roles.nonMember)
  
  function handleResize() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    fetch(`${props.constants.hostname}/api/checkAuth`, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
    })
    .then((data) => {
        if (!data) { return; }
        setRole(data.role)
    })
  }, [])

  function flipDropdown() {
    setDropdown(!dropdown)
  }

  const theme = localStorage.getItem(THEME)
  return (
    <div id={`${props.cssOff ? '' : 'navBar'}`} className={`${props.cssOff ? '' : `header header${theme}`}`}>
      <div className={`${props.cssOff ? '' : `bannerContainer bannerContainer${theme}`}`}>
        <Link to={'/home'}>
          <img
            src={'/media/banner.png'}
            alt='Pepband Banner'
            className={`${props.cssOff ? '' : 'banner'}`}
          />
        </Link>
      </div>
      <div className={`${props.cssOff ? '' : 'dropdownMain'}`}>
        <FontAwesomeIcon
          icon='bars'
          className={`${props.cssOff ? '' : 'fa drop'}`}
          onClick={() => flipDropdown()}
        />
        {(width < 768) && (dropdown) ?
          null
          :
          <div className={`${props.cssOff ? '' : 'nav'}`}>
            <ul className={`${props.cssOff ? '' : 'navUI'}`}>
              {authRole(role, props.constants.roles.alumni) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/home'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='home' className={`${props.cssOff ? '' : 'fa'}`}/>
                    Home
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.president) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/president'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='crown' className={`${props.cssOff ? '' : 'fa'}`}/>
                    President
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.conductor) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/conductor'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='music' className={`${props.cssOff ? '' : 'fa'}`}/>
                    Conductor
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.secretary) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/secretary'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='user-edit' className={`${props.cssOff ? '' : 'fa'}`}/>
                    Secretary
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.alumni) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/eboard'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='graduation-cap' className={`${props.cssOff ? '' : 'fa'}`}/>
                    EBoard
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.alumni) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/feedback'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='inbox' className={`${props.cssOff ? '' : 'fa'}`}/>
                    Feedback
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.alumni) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/vote'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                  <FontAwesomeIcon icon='check-square' className={`${props.cssOff ? '' : 'fa'}`}/>
                    Voting
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.alumni) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/documents'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='folder-open' className={`${props.cssOff ? '' : 'fa'}`}/>
                    Documents
                  </li>
                </Link>) : (null)
              }
              {authRole(role, props.constants.roles.member) ?
                (<Link
                  className={`${props.cssOff ? '' : 'navLink'}`}
                  to='/attendance'
                  onClick={() => {
                    setDropdown(false)
                  }}
                >
                  <li className={`${props.cssOff ? '' : 'navElement'}`}>
                    <FontAwesomeIcon icon='address-card' className={`${props.cssOff ? '' : 'fa'}`}/>
                    My Attendance
                  </li>
                </Link>) : (null)
              }
              <Link
                className={`${props.cssOff ? '' : 'navLink'}`}
                to='/chants'
                onClick={() => {
                  setDropdown(false)
                }}
              >
                <li className={`${props.cssOff ? '' : 'navElement'}`}>
                  <FontAwesomeIcon icon='file-alt' className={`${props.cssOff ? '' : 'fa'}`}/>
                  Chants
                </li>
              </Link>
            </ul>
          </div>
        }
      </div>
    </div>
  );
}