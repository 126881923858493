import React, { useEffect, useState } from 'react';
import { USERID } from '../App';
import '../../css/eboard.css';

export default function EBoard(props) {
  const [eboard, setEboard] = useState([])
  const [sLeads, setSleads] = useState([])

  useEffect(() => {
    let url = `${props.constants.hostname}/api/eboard`;
    fetch(url, {
      headers: { Authorization: `${localStorage.getItem(USERID)}` }
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then((data) => {
        setEboard(data)
      })
      .catch((error) => { console.error(error); });

    url = `${props.constants.hostname}/api/sectionLeaders`;
    fetch(url, {
      headers: { Authorization: `${localStorage.getItem(USERID)}` }
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then((data) => {
        setSleads(data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  function formatPhoneNumber(phoneNumberString) {
    return `(${phoneNumberString.slice(0, 3)}) ${phoneNumberString.slice(3, 6)}-${phoneNumberString.slice(6)}`;
  }

  return (
    <div id='Eboard'>
      <h1>Primaries</h1>
      <div class='eboard-section' id="primaries">
        {eboard.map((person) =>
          <div id="eboard-person" key={person.Email}>
            <img
              src={`/media/eboard_pics/${person.Slug}.jpg`}
              alt={person.Position}
              className={`${props.cssOff ? '' : 'eboardImage'}`}
            >
            </img>
            <div id="eboard-info">
              <h2 className={`${props.cssOff ? '' : 'eboard'}`}>{person.Position}</h2>
              <hr></hr>
              <h3 className={`${props.cssOff ? '' : 'eboard'}`}>{person.FirstName} {person.LastName}</h3>
              <div className={`${props.cssOff ? '' : 'eboard'}`}><a href={`tel:${person.Cell}"`}>{formatPhoneNumber(person.Cell)}</a></div>
              <div className={`${props.cssOff ? '' : 'eboard'}`}><a href={`mailto:${person.Email}`}>{person.Email}</a></div>
            </div>
          </div>
        )}
      </div>
      <h1>Section Leaders</h1>
      <div class='eboard-section' id="section-leads">
        {sLeads.map((person) =>
          <div id="eboard-person" key={person.Email}>
            <img
              src={`/media/eboard_pics/${person.Section}.jpg`}
              alt={person.Section}
              className={`${props.cssOff ? '' : 'eboardImage'}`}
            >
            </img>
            <div id="eboard-info">
              <h2 className={`${props.cssOff ? '' : 'eboard'}`}>{person.Section}</h2>
              <hr></hr>
              <h3 className={`${props.cssOff ? '' : 'eboard'}`}>{person.FirstName} {person.LastName}</h3>
              <div className={`${props.cssOff ? '' : 'eboard'}`}><a href={`tel:${person.Cell}`}>{person.Cell}</a></div>
              <div className={`${props.cssOff ? '' : 'eboard'}`}><a href={`mailto:${person.Email}`}>{person.Email}</a></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
