import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faEye, faCrown, faMusic, faUserEdit, faGraduationCap, faUser, faFolderOpen, faAddressCard, faFileAlt, faSignOutAlt, faAlignJustify, faUsers, faPlusCircle, faTrashAlt, faUserPlus, faFolderPlus, faCalendarAlt, faCalendarPlus, faCalendarCheck, faCalendarWeek, faImage, faFileImage, faSave, faCheckSquare, faSync, faClipboard, faFileDownload, faChartBar, faClipboardList, faCheck, faQuestion, faTimes, faInbox, faBars } from '@fortawesome/free-solid-svg-icons'
import Login from './Login';
import NavBar from './NavBar';
import Footer from './Footer';
import Loading from './Loading';
import {blankCheck, passCheck, eventCheck} from './Errors'
import { fireauth, authRole } from '../index';
import '../css/main.css';
import Routing from './Routing';
import { Route, Routes } from 'react-router-dom';
import Show from './routes/Show';

library.add(faHome, faEye, faCrown, faMusic, faUserEdit, faGraduationCap, faUser, faFolderOpen, faAddressCard, faFileAlt, faSignOutAlt, faAlignJustify, faUsers, faPlusCircle, faTrashAlt, faUserPlus, faFolderPlus, faCalendarAlt, faCalendarPlus, faCalendarCheck, faCalendarWeek, faImage, faFileImage, faSave, faCheckSquare, faSync, faClipboard, faFileDownload, faChartBar, faClipboardList, faCheck, faQuestion, faTimes, faInbox, faBars)

export const USERID = 'user_id'
export const THEME = 'theme'
export const THEMELIST = {default: 'Default',
                          dark: 'Dark', 
                          red: 'Red', 
                          blue: 'Blue', 
                          green: 'Green', 
                          purple: 'Purple', 
                          rainbow: 'Rainbow', 
                          trans: 'Trans', 
                          enby: 'Enby', 
                          bi: 'Bi', 
                          ace: 'Ace', 
                          pan: 'Pan'}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.props.state,
      username: '',
      userRole: '',
      password: '',
      failed: false,
      classic: true,
      loaded: false,
      noCSS: false,
    }
  }

  componentDidMount() {
    if(localStorage.getItem(USERID) !== undefined && localStorage.getItem(USERID) != null) {
      this.props.state.user = 'alumni';
      this.setState({ userRole: 'alumni', user: 'alumni' });
      fetch(`${this.state.constants.hostname}/api/checkAuth`, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}})
        .then((res) => {
          if (!res.ok) {
            localStorage.removeItem(USERID);
            window.location = '/';
          }
          return res.json();
        })
        .then((data) => {
          if (!data) { return; }
          this.setState({ userRole: data.role, loaded: true});
        })
        .catch((error) => { console.error(error); })
    } else {
      fireauth.onAuthStateChanged((user) => {
        this.handleAuthChange(user);
        this.setState({ user });
      });
    }
    if(localStorage.getItem(THEME) === undefined || localStorage.getItem(THEME) === null) {
      localStorage.setItem(THEME, THEMELIST.default)
    }
  }

  handleAuthChange(user) {
    if (user) {
    const url = `${this.state.constants.hostname}/api/userRole?userID=${user.uid}&email=${user.email}`;
    fetch(url)
      .then((res) => {
        if (!res.ok) {
          this.setState({ userRole: this.state.constants.roles.nonMember });
          return false;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) { return; }
        localStorage.setItem(USERID, data.user);
        localStorage.setItem(THEME, THEMELIST.default)
        this.setState({ userRole: data.role, loaded: true });
      })
      .catch((error) => { console.error(error); });
    }
    else {
      this.setState({ userRole: this.state.constants.roles.nonMember });
    }
  }

  errorCheck = () => {
    if (blankCheck(this.state.username)) {
      console.error('Blank field: username')
      alert('Please enter a username')
      return false;
    }
    if (blankCheck(this.state.password)) {
      console.error('Blank field: password')
      alert('Please enter a password')
      return false;
    }
    if (eventCheck(this.state.username)) {
      return false;
    }
    if (passCheck(this.state.password)) {
      return false;
    }
    return true;
  }

  handleCSSToggle = () => {
    this.setState({noCSS: !this.state.noCSS})
  }

  handleLogin = (event) => {
    event.preventDefault();
    const url = `${this.state.constants.hostname}/api/alumni/login`;
    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: this.state.username, password: this.state.password })
    })
      .then((res) => {
        if (!res.ok) {
          this.setState({ userRole: this.state.constants.roles.nonMember });
          return false;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) { return; }
          if (data.role === this.state.constants.roles.nonMember) {
            this.setState({username: '', password: '', failed: true})
          } else {
            this.setState({ userRole: data.role, user: 'alumni' });
            localStorage.setItem(USERID, data.user);
            this.props.state.user = 'alumni'
            this.setState({loaded: true})
          }
      })
      .catch((error) => { console.error(error); });
  }

  onUsernameChange = (event) => {
    this.setState({username: event.target.value})
  }

  onPassChange = (event) => {
    this.setState({password: event.target.value})
  }

  switchClassic = () => {
    this.setState({classic: !this.state.classic })
  }

  render() {
    if (!(this.state.userRole)) {
      return (null);
    }
    if (!(this.state.user) && (window.location.pathname !== '/chants')) {
      return (
        <Login
          message='You are not logged in.'
          state={this.state}
          handleLogin={this.handleLogin}
          userChange={this.onUsernameChange}
          passChange={this.onPassChange}
          switchClassic={this.switchClassic}
        />
      );
    }
    if (!(authRole(this.state.userRole, this.state.constants.roles.alumni)) && (window.location.pathname !== '/chants')) {
      return (
        <div id='nonMember'>
          <Login
            message='You are not a member. Please login using your @g.rit.edu account.'
            state={this.state}
          />
        </div>
      );
    }
    if(!(this.state.loaded) && this.state.user) {
      return (
        <div>
          <Loading noCSS={this.state.noCSS}/>
        </div>
      )
    }
    const theme = localStorage.getItem(THEME)
    return (
      <div id='app'>
        <Routes>
          <Route exact path='music/:title/show/:file' element={<Show constants={this.state.constants}/>}/>
          <Route index path='/*' element={
            <div>
              <div className={`${this.state.noCSS ? '' : `content-wrap content-wrap${theme}`}`}>
                <div>
                  <NavBar constants={this.state.constants} cssOff={this.state.noCSS}/>
                  <div className={`${this.state.noCSS ? '' : 'body'}`}>
                    <div>
                      <Routing state={this.state} handleCSSToggle={this.handleCSSToggle} cssOff={this.state.noCSS}/>
                    </div>
                  </div>
                </div>
                <Footer cssOff={this.state.noCSS}/>
              </div>
              {/* <div className={`${this.state.noCSS ? '' : `content-wrap${theme}`}`} /> */}
            </div>
          }/>
        </Routes>
      </div>
    );
  }
}

export default App;
