import React, { useEffect, useState } from 'react';
import { blankCheck, eventCheck, dateCheck } from '../../Errors';
import { USERID } from '../../App';
import { useNavigate, useParams } from 'react-router-dom';

export default function EventEdit(props) {
  const params = useParams()
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [type, setType] = useState('Rehearsal')
  const [rehearsal, setRehearsal] = useState('Y')
  const [error, setError] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [edit, setEdit] = useState(false)
  const [eid, setEID] = useState(params.eventID)
  const navigate = useNavigate()

  useEffect(() => {
    if (params.eventID != null && params.eventID !== undefined) {
      const url = `${props.constants.hostname}/api/secretary/event?editEvent=${eid}`;
      fetch(url, {
        headers: {Authorization: `${localStorage.getItem(USERID)}`}
      })
      .then((res) => {
        if (!res.ok) {
          return false;
        }
        else {
          return res.json();
        }
      })
      .then((data) => {
        setName(data[0].Name)
        setDate(data[0].Date)
        setType(data[0].Type)
        setEdit(true)
        setEID(data[0].Eid)
      })
      .catch((e) => { console.error(e); })
    }
  }, [])

  function errorCheck() {
    if (blankCheck(name)) {
      console.error('Blank field: Name')
      alert('Blank field: First Name')
      return false;
    }
    if (eventCheck(name)) {
      console.error(`Invalid Name: ${name}`);
      alert(`Invalid Name: ${name}\nPlease fix input`);
      return false;
    }
    if (blankCheck(date)) {
      console.error('Blank field: Date')
      alert('Blank field: Date')
      return false;
    }
    if (dateCheck(date)) {
      console.error('Date is too far into the past or future');
      alert('Date is too far into the past or future');
      return false;
    }
    if (blankCheck(type)) {
      console.error('Blank field: Type')
      alert('Blank field: Type')
      return false;
    }
    return true;
  }

  function submitData() {
    if(errorCheck()) {
      setError(false)
      const semester = parseInt(date.split('-')[1]);
      const event = {
        Name: name,
        Date: date,
        Rehearsal: rehearsal,
        Semester: semester >= 6 ? 'Fall' : 'Spring',
        Type: type,
        Eid: eid
      }
      const url = `${props.constants.hostname}/api/secretary/addEvent`;
      if (edit) {
        fetch(url, {
          body: JSON.stringify({ event: event }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem(USERID)}`
          },
          method: 'PUT',
        })
        .then((res) => {
          if (!res.ok) {
            setError(true)
            return false;
          }
          else {
            setSubmit(true)
            return true;
          }
        })
        .catch((e) => { console.error(e); })
      } else {
        fetch(url, {
          body: JSON.stringify({ event: event }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${localStorage.getItem(USERID)}`
          },
          method: 'POST',
        })
        .then((res) => {
          if (!res.ok) {
            setError(true)
            return false;
          }
          else {
            setSubmit(true)
            return true
          }
        })
        .catch((e) => { console.error(e); })
      }
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitData()
  }

  if(submit) {
    navigate('/secretary/home')
  }

  return (
    <div>
      {error ?
        <h2>An Error Occurred: Event Already Exists</h2>
        :
        null
      }
      <h3>Event Information:</h3>
      <hr />
      <form className='userForm'>
        <label className='secretaryLabel'>
          Event Name:
          <input
            type='text'
            name='Name'
            onChange={(e) => { setName(e.target.value) }}
            value={name}
            className='secretaryInput'
            maxLength='60'
          />
        </label>
        <label className='secretaryLabel'>
          Event Date:
          <input
            type='datetime-local'
            name='Date'
            className='secretaryInput'
            value={date}
            onChange={(e) => { setDate(e.target.value) }}
            min='2019-06-07T00:00'
          />
        </label>
        <label className='secretaryLabel'>
          Event Type:
          <select
            value={type}
            onChange={(e) => { setType(e.target.value) }}
            className='secretaryInput'
          >
            <option default value='Rehearsal'>Rehearsal</option>
            <option value='MHockey'>Men's Hockey</option>
            <option value='WHockey'>Women's Hockey</option>
            <option value='MSoccer'>Men's Soccer</option>
            <option value='WSoccer'>Women's Soccer</option>
            <option value='MLax'>Men's Lacrosse</option>
            <option value='WLax'>Women's Lacrosse</option>
            <option value='Other'>Other</option>
          </select>
        </label>
        <label className='secretaryLabel'>
          Does this event count as a rehearsal?
          <select
            value={rehearsal}
            onChange={(e) => { setRehearsal(e.target.value) }}
            className='secretaryInput'
          >
            <option default value='Y'>Yes</option>
            <option value='N'>No</option>
          </select>
        </label>
        <button
          onClick={handleSubmit}
          className='secretarySubmit'
        >
          Submit
        </button>
      </form>
    </div>
  );
}
