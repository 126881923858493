import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactMarkdown from 'react-markdown';
import '../../../css/qdb.css';
import { USERID } from '../../App';
import { Link, useNavigate } from 'react-router-dom';
import { authRole } from '../../..';

export default function ApproveQuote(props) {
  const navigate = useNavigate()
  if (!authRole(props.userRole, props.constants.roles.admin)) {
     navigate('/404', {replace: true})
  }

  const [quotes, setQuotes] = useState([])

  useEffect(() => {
    const url = `${props.constants.hostname}/api/qdb/private`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then((data) => {
      if (!data) { return; }
      setQuotes(data)
    })
    .catch((error) => { console.error(error); });
  }, [])

  function confirmApprove(qid) {
    if (window.confirm('Approve this quote?')) {
      const url = `${props.constants.hostname}/api/qdb/approveQuote`;
      fetch(url, {
        body: JSON.stringify({qid: qid}),
        headers: {
          Authorization: `${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json'
        },
        method: 'PATCH',
      })
      .then((res) => {
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .then(() => {
        const tempQuotes = [];
        quotes.forEach((quote) => {
          if (quote.QID !== qid) {
            tempQuotes.push(quote);
          }
        });
        setQuotes(tempQuotes)
        window.alert('Successful Approval');
      })
      .catch((error) => { console.error(error); });
    }
  }

  function confirmDel(qid) {
    if (window.confirm('Delete this quote?')) {
      const url = `${props.constants.hostname}/api/qdb/removeQuot`;
      fetch(url, {
        body: JSON.stringify({QID: qid}),
        headers: {
          Authorization: `${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      })
      .then((res) => {
        if (!res.okay) {
          return false;
        }
        return res.json();
      })
      .then(() => {
        const tempQuotes = [];
        quotes.forEach((quote) => {
          if (quote.QID !== qid) {
            tempQuotes.push(quote);
          }
        });
        setQuotes(tempQuotes)
        window.alert('Successful deletion');
      })
      .catch((error) => { console.error(error); });
    }
  }

  return (
    <div id='Qdb'>
      <h2>QDB</h2>
      <div className='oneline'>
        <Link to='/qdb/addQuote' className='secBarLink'>
          <div className='oneline'>
            <FontAwesomeIcon icon='plus-circle' className='faAdd'/>
            <p className='secBarText'>
              Add Quote
            </p>
          </div>
        </Link>
        <Link to='/qdb/home' className='secBarLink'>
          <div className='oneline'>
            <FontAwesomeIcon icon='home' className='faAdd'/>
            <p className='secBarText'>
              Quotes Home
            </p>
          </div>
        </Link>
      </div>
      <table className='QDBApproval'>
        <tbody>
          <tr>
            <th className='QDBApproval'/>
            <th className='QDBApproval'>Title</th>
            <th className='QDBApproval'>Date</th>
            <th className='QDBApproval'>Person</th>
            <th className='QDBApproval'>Quote</th>
            <th className='QDBApproval'/>
          </tr>
          {quotes.map((quote) =>
            <tr key={quote.QID}>
            <td className='QDBApproval'>
              <FontAwesomeIcon
                icon='check-square'
                className='faApprove'
                onClick={() => confirmApprove(quote.QID)}
              />
            </td>
              <td className='QDBApproval'>{quote.Title}</td>
              <td className='QDBApproval'>{quote.Date}</td>
              <td className='QDBApproval'>{quote.Person}</td>
              <td className='QDBApproval multiline'>
              <ReactMarkdown>{quote.Quote}</ReactMarkdown>
              </td>
              <td className='QDBApproval'>
              <FontAwesomeIcon
                icon='trash-alt'
                className='faDelete'
                onClick={() => confirmDel(quote.QID)}
              />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
