import React from 'react';
import { THEME } from './App';

export default function Footer(props) {
  const theme = localStorage.getItem(THEME)
  return (
    <footer className={`${props.cssOff ? '' : `footer footer${theme}`}`}>
      <div className={`${props.cssOff ? '' : 'center footText'}`}>Updated for the 2024-2025 Academic Year</div>
      <div className={`${props.cssOff ? '' : 'center footText'}`}>For Use by the RIT Pep Band Only</div>
    </footer>
  );
}
