import React from 'react';
import Home from './routes/Home';
import President from './routes/President';
import Conductor from './routes/Conductor';
import Secretary from './routes/Secretary';
import EBoard from './routes/EBoard';
import Documents from './routes/Documents';
import Attendance from './routes/Attendance';
import Chants from './routes/Chants';
import History from './routes/History';
import Mayonnaise from './routes/Mayonnaise';
import Ready from './routes/Ready';
import Memes from './routes/Memes'
import MusicList from './routes/MusicList';
import Music from './routes/Music';
import Qdb from './routes/Qdb';
import AntiCss from './routes/AntiCss';
import Vote from './routes/Vote'
import Rickroll from './routes/Rickroll';
import NotFound from './routes/NotFound';
import Feedback from './routes/Feedback';
import { Navigate, Route, Routes } from 'react-router-dom';
import Themes from './routes/Themes';

export default function Routing(props) {
    return (
        <Routes path='/'>
            <Route index element={<Navigate to="home" replace/>} />
            <Route path='home' element={<Home constants={props.state.constants} cssOff={props.cssOff}/>}/>
            <Route path='president' element={(<President constants={props.state.constants} cssOff={props.cssOff} userRole={props.state.userRole}/>)}/>
            <Route path='conductor' element={<Conductor constants={props.state.constants} cssOff={props.cssOff} userRole={props.state.userRole}/>}/>
            <Route path='secretary/*' element={(<Secretary constants={props.state.constants} cssOff={props.cssOff} userRole={props.state.userRole}/>)}/>
            <Route path='eboard' element={<EBoard constants={props.state.constants} cssOff={props.cssOff}/>}/>
            <Route path='vote/*' element={<Vote state={props.state} cssOff={props.cssOff}/>}/>
            <Route path='documents' element={<Documents cssOff={props.cssOff}/>}/>
            <Route path='feedback/*' element={<Feedback constants={props.state.constants} userRole={props.state.userRole} cssOff={props.cssOff}/>}/>
            <Route path='attendance' element={<Attendance constants={props.state.constants} cssOff={props.cssOff}/>}/>
            <Route path='chants' element={<Chants cssOff={props.cssOff}/>}/>
            <Route path='history/*' element={<History cssOff={props.cssOff}/>}/>
            <Route path='qdb/*' element={<Qdb constants={props.state.constants} cssOff={props.cssOff} userRole={props.state.userRole}/>}/>
            <Route caseSensitive path='Music' element={<Rickroll/>}/>
            <Route caseSensitive path='music' element={<MusicList constants={props.state.constants} cssOff={props.cssOff}/>}/>
            <Route caseSensitive path='music/:song' element={<Music constants={props.state.constants} cssOff={props.cssOff}/>}/>
            <Route path='mayonnaise' element={<Mayonnaise/>}/>
            <Route path='anticsschurch' element={<AntiCss handleCSSToggle={props.handleCSSToggle} cssOff={props.cssOff}/>}/>
            <Route path='ready' element={<Ready cssOff={props.cssOff}/>}/>
            <Route path='memes' element={<Memes/>}/>
            <Route path='themes' element={<Themes/>}/>
            <Route path='404' element={<NotFound/>} cssOff={props.cssOff}/>
            <Route path="*" element={<Navigate to="404" replace />} />
        </Routes>
    )
}