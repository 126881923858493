import React, { useEffect, useState } from 'react';
import { USERID } from '../App';

export default function Attendance(props) {
  const [attendance, setAttendance] = useState({rehearsals: 0, events: 0, mHockey: 0, wHockey: 0, mSoccer: 0, wSoccer: 0, mLax: 0, wLax: 0, other: 0, exempt: false, exemptDesc: '', FirstName: '', LastName: '', WGameGo: false, MGameGo: false, GStanding: false, FGStanding: false,})

  function handleAssign(data) {
    const newAttendance = {...attendance}
    data.forEach((element) => {
      if (element.Rehearsal === 'Y') {
        newAttendance.rehearsals = attendance.rehearsals + element.count
        setAttendance(newAttendance)
      }
      switch(element.Type) {
        case 'Rehearsal':
          break;
        case 'MHockey':
          newAttendance.mHockey = attendance.mHockey + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        case 'WHockey':
          newAttendance.wHockey = attendance.wHockey + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        case 'MSoccer':
          newAttendance.mSoccer = attendance.mSoccer + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        case 'WSoccer':
          newAttendance.wSoccer = attendance.wSoccer + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        case 'MLax':
          newAttendance.mLax = attendance.mLax + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        case 'WLax':
          newAttendance.wLax = attendance.wLax + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        case 'Other':
          newAttendance.other = attendance.other + element.count
          newAttendance.events = attendance.events + element.count
          setAttendance(newAttendance)
          break;
        default:
          console.error('Unhandled event type. Please let webmaster know');
      }
    });
    setAttendance(newAttendance)
  }

  useEffect(() => {
    let url = `${props.constants.hostname}/api/attendance`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      handleAssign(data)
    })
    .catch((error) => { console.error(error); })

    url = `${props.constants.hostname}/api/exempt`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      if (data[0].Exempt === 1) {
        const newAttendance = {...attendance}
        newAttendance.exempt = data[0].Exempt
        newAttendance.exemptDesc = data[0].ExemptDesc
        setAttendance(newAttendance)
      }
    })
    .catch((error) => { console.error(error); })

    url = `${props.constants.hostname}/api/statuses`;
    fetch(url, {
      headers: {Authorization: `${localStorage.getItem(USERID)}`}
    })
    .then((res) => {
      if (!res.ok) {
        return false;
      }
      else {
        return res.json();
      }
    })
    .then((data) => {
      const newAttendance = {...attendance}
      newAttendance.FirstName = data.FirstName
      newAttendance.LastName = data.LastName
      newAttendance.WGameGo = data.WGameGo
      newAttendance.MGameGo = data.MGameGo
      newAttendance.GStanding = data.GStanding
      newAttendance.FGStanding = data.FGStanding
      setAttendance(newAttendance)
    })
    .catch((error) => { console.error(error); })
  }, [])

  function warningController() {
    if (attendance.rehearsals < 3) {
      if (attendance.exempt) {
        return (
          <div>
            <div className='oneline attendanceh3'>
              <h3 className='attendanceh3'>
                You currently have exemption from the following event types:
              </h3>
              <h3 className='attendanceh3' style={{marginLeft: '5px'}}>
                {attendance.exemptDesc}
              </h3>
            </div>
            <h3 className='attendanceh3'>
              This may or may not allow you to attend games.
            </h3>
          </div>
        );
      }
      else if (attendance.rehearsals === 2) {
        return (
          <h3 className='attendanceh3' id='warning'>
            Warning: You currently cannot attend men's hockey games
          </h3>
        );
      }
      return (
        <h3 className='attendanceh3' id='warning'>
          Warning: You currently cannot attend men's or women's hockey games
        </h3>
      )
    }
    return null;
  }

  return (
    <div id='attendance'>
      <h1>
        Attendance for:<br></br>{attendance.LastName}, {attendance.FirstName}
      </h1>
      {warningController()}
      <div>
        <h3 className='attendanceh3'>
          Rehearsals: {attendance.rehearsals}
        </h3>
        <h3 className='attendanceh3'>
          Events: {attendance.events}
        </h3>
        <h3 className='attendanceh3'>
          Good Standing (Current Semester): {attendance.GStanding === 1 ? 'True' : 'False'}
        </h3>
        <h3 className='attendanceh3'>
          Projected Good Standing (For Future Semester): {attendance.FGStanding === 1 ? 'True' : 'False'}
        </h3>
        <p>Men's Hockey Games: {attendance.mHockey}</p>
        <p>Women's Hockey Games: {attendance.wHockey}</p>
        <p>Men's Soccer Games: {attendance.mSoccer}</p>
        <p>Women's Soccer Games: {attendance.wSoccer}</p>
        <p>Men's Lacrosse Games: {attendance.mLax}</p>
        <p>Women's Lacrosse Games: {attendance.wLax}</p>
        <p>Other events: {attendance.other}</p>
      </div>
      <br/>
      <div>
        <div id='disclaimer'>
          <b>Disclaimer:</b> <br></br>
          <i> - These may or may not be up to date with the latest events <br></br>
          - If something doesn't look right please contact the secretary <br></br>
          - Good Standing calculations can be found in the constitution <br></br>
        </i></div>
      </div>
    </div>
  );
}
